<!--
 * @Author       : JiangChao
 * @Date         : 2022-11-28 14:35:40
 * @LastEditors  : JiangChao
 * @LastEditTime : 2023-05-29 22:36:45
 * @Description  : 個人信息頁
-->
<template>
    <div class="container">
        <van-form @submit="onSubmit">
            <div class="title"><span>基本資料</span></div>
            <div class="layout">
                <div>ID</div>
                <div>{{ this.userId }}</div>
            </div>

            <div class="layout" @click="uploadHeader">
                <div>頭像</div>
                <!-- <img src="../assets/imgs/png/icon_register_logo.png" width="50px" alt=""> -->
                <van-uploader
                    :after-read="afterCard"
                    :before-read="beforeRead"
                    accept="image/*"
                    class="arrart"
                >
                    <van-image
                        class="arrart"
                        :src="this.avatar"
                        :error-icon="
                            require('../assets/imgs/png/icon_register_logo.png')
                        "
                    />
                </van-uploader>
            </div>

            <div class="layout">
                <van-field
                    v-model="nickName"
                    label="暱稱"
                    placeholder="请输入暱稱"
                    input-align="right"
                />
            </div>

            <div class="layout">
                <van-field
                    v-model="email"
                    label="E-MAIL"
                    placeholder="请输入郵箱"
                    input-align="right"
                />
            </div>

            <div class="layout">
                <div>手機</div>
                <div>{{ this.mobile }}</div>
            </div>

            <div class="layout" style="margin-top: 10px" @click="logout">
                <div>退出登錄</div>
                <div></div>
            </div>
            <van-button block native-type="submit" class="confirm"
                >確認</van-button
            >

            <div class="bottom-name">
                <img :src="require('@/assets/imgs/svg/0527-c.svg')" />
            </div>
        </van-form>
    </div>
</template>

<script>
import { handleErr } from "@/helpers";
// import Compressor from "compressorjs";
import { Toast } from "vant";
import * as imageConversion from "image-conversion";

export default {
    name: "",
    data() {
        return {
            userId: "",
            nickName: "",
            email: "",
            headerUrl: "",
            avatar: "",
            mobile: "",
        };
    },
    beforeCreate() {
        const token = window.$cookies.get("token");
        if (token) {
            this.$axios.defaults.headers.common["token"] = token;
            this.$axios.defaults.headers.common["appType"] = "PHONE";
        }
    },
    methods: {
        async onSubmit() {
            try {
                const { data } = await this.$axios.post("customer/upUserInfo", {
                    nickName: this.nickName,
                    email: this.email,
                });
                if (data.success) {
                    localStorage.setItem("nickName", this.nickName);
                    localStorage.setItem("email", this.email);
                    Toast("更新資訊成功");
                    this.$router.push("map");
                }
            } catch (error) {
                handleErr({ ...error, app: this.$parent });
            }
        },
        logout() {
            localStorage.setItem("user_mobile", "");
            localStorage.setItem("id", "");
            localStorage.setItem("email", "");
            localStorage.setItem("avatar", "");
            localStorage.setItem("nickName", "");

            localStorage.setItem("subscribe", false);
            localStorage.setItem("money", 0);
            localStorage.setItem("startExpireDate", "");
            localStorage.setItem("freeHour", 0);
            localStorage.setItem("bindCardStatus", false);
            localStorage.setItem("expireDate", "");

            //1.方式1
               delete this.$axios.defaults.headers.common["token"];
            delete this.$axios.defaults.headers.common["appType"];
            
            //2.方式2
        //  this.$axios.defaults.headers.common["token"] = "";
            // this.$axios.defaults.headers.common["appType"] = "";

            window.$cookies.set("token", "");
            // this.signOutWithGoogle();
            this.$router.push("/");
        },
        // signOutWithGoogle() {
        //     window.google.accounts.oauth2.revoke(window.access_token, () => {
        //         console.log("access token revoked");
        //     });
        // },
        uploadHeader() {
            console.log("上传头像");
        },

        // 上传之前的图片验证
        beforeRead(file) {
            // let quality = 1;
            // if (file.size < 1000 * 1000) {
            //     //小于1M
            //     console.log("小于1M: ");
            //     quality = 0.8;
            // } else if (file.size < 5000 * 1000) {
            //     //小于5M
            //     console.log("小于5M: ");
            //     quality = 0.5;
            // } else if (file.size < 10000 * 1000) {
            //     //小于10M
            //     console.log("小于10M: ");
            //     quality = 0.3;
            // } else {
            //     //大于10M
            //     console.log("大于10M: ");
            //     quality = 0.1;
            // }
            console.log("壓縮前file: ", file.size);
            // return new Promise((resolve) => {
            //     // compressorjs 默认开启 checkOrientation 选项
            //     // 会将图片修正为正确方向
            //     new Compressor(file, {
            //         success: resolve,
            //         quality: quality,
            //         error(err) {
            //             console.log(err.message);
            //         },
            //     });
            // });

            return new Promise((resolve) => {
                // 压缩到100KB,这里的100就是要压缩的大小,可自定义
                imageConversion.compressAccurately(file, 100).then((res) => {
                    console.log(res);
                    resolve(res);
                });
            });
        },
        // 头像上传  文件上传完毕后会触发 after-read 回调函数，获取到对应的 file 对象。
        afterCard(file) {
            console.log("file: ", file);
            this.upload(file);
        },
        async upload(file) {
            console.log("壓縮後file: ", file.file.size);
            try {
                const { data } = await this.$axios.post(
                    "customer/upload-avatar",
                    { fileBase: file.content }
                );
                if (data.success) {
                    this.avatar =
                        "https://s3lvelect.s3.ap-east-1.amazonaws.com/" +
                        data.result;
                    localStorage.setItem("avatar", data.result);
                }
            } catch (error) {
                handleErr({ ...error, app: this.$parent });
            }
        },
        async getUserInfo() {
            try {
                const res = await this.$axios.get("customer/user-info");
                console.log("res: ", res);
                if (res.data.success) {
                    const subscribe =
                        res.data.result.subscribe == true ? "1" : "0";
                    const money = res.data.result.money;
                    const startExpireDate = res.data.result.startExpireDate;
                    const freeHour = res.data.result.freeHour;
                    const bindCardStatus =
                        res.data.result.bindCardStatus == true ? "1" : "0";
                    const expireDate = res.data.result.expireDate;
                    const avatar = res.data.result.avatar;
                    this.avatar =
                        "https://s3lvelect.s3.ap-east-1.amazonaws.com/" +
                        avatar;
                    console.log("avatar: ", avatar);
                    localStorage.setItem("avatar", avatar);
                    localStorage.setItem("subscribe", subscribe);
                    console.log("subscribe: ", subscribe);
                    localStorage.setItem("money", money);
                    localStorage.setItem("startExpireDate", startExpireDate);
                    localStorage.setItem("freeHour", freeHour);
                    localStorage.setItem("bindCardStatus", bindCardStatus);
                    console.log("bindCardStatus: ", bindCardStatus);
                    localStorage.setItem("expireDate", expireDate);
                    this.bindCardStatus =
                        Number(localStorage.getItem("bindCardStatus")) == "1"
                            ? true
                            : false;
                    this.subscribe =
                        Number(localStorage.getItem("subscribe")) == "1"
                            ? true
                            : false;
                    this.money = money;
                    this.expireDate = expireDate;
                    console.log("this.money: ", this.money);
                    this.userId = res.data.result.id;
                    this.nickName = res.data.result.nickName;
                    this.mobile = res.data.result.mobile;
                    this.email = res.data.result.email;
                }
            } catch (error) {
                console.log(error, "store error");
                handleErr({ ...error, app: this.$parent });
            }
        },
    },
    props: {},
    components: {},
    computed: {},
    mounted() {
        this.getUserInfo();
    },
    created() {},
};
</script>

<style scoped lang="less">
.title {
    background: #fee26c;
    text-align: center;
    padding: 10px;
}
.layout {
    display: flex;
    background: #ffffff;
    flex-direction: row;
    justify-content: space-between;
    line-height: 50px;
    height: 50px;
    padding: 3px 10px;
    margin-top: 1px;
    /deep/.van-field__label {
        color: #000000;
        margin-left: -15px;
        font-size: 16px;
    }
    /deep/.van-field__value {
        color: #000000;
        font-size: 16px;
    }
}
.confirm {
    background: #2c394e;
    border-radius: 25px;
    height: 50px;
    color: #ffffff;
    text-align: center;
    line-height: 50px;
    // margin: 20px;
    margin-top: 20px;
}

.arrart {
    width: 45px;
    height: 45px;
    border-radius: 50%;
}
</style>
